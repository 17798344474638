<template>
  <v-container
    id="grid-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <material-card
          color="success"
          full-header
        >
          <template #heading>
            <v-tabs
              v-model="progressionStateTab"
              background-color="transparent"
              color="white"
              slider-color="white"
              class="pa-4"
              centered
            >
              <v-tab
                class="mr-3"
                :to="'/progression/available'"
                exact
                replace
              >
                <v-icon class="mr-2">
                  mdi-clipboard-text
                </v-icon>
                Available
              </v-tab>
              <v-tab
                class="mr-3"
                :to="'/progression/locked'"
                exact
                replace
              >
                <v-icon class="mr-2">
                  mdi-lock
                </v-icon>
                Locked
              </v-tab>
              <v-tab
                :to="'/progression/completed'"
                exact
                replace
              >
                <v-icon class="mr-2">
                  mdi-clipboard-check
                </v-icon>
                Completed
              </v-tab>
            </v-tabs>
          </template>
          <router-view
            :availability="availability"
          />
        </material-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="3"
        align="center"
      >
          <a
            :href="traderLink"
            target="_blank"
          >
            <v-progress-circular
              rotate="270"
              :value="progressPercent"
              width="8"
              color="objectivecomplete"
              size="150"
            >
              <!-- <img
                class="trader-avatar elevation-6"
                :src="traderIcon(traderName)"
                width="100% vw"
              > -->
            </v-progress-circular>
          </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      availability: {
        type: String,
      },
    },
    data () {
      return {
        progressionStateTab: 0,
      }
    },
    computed: {
      progressPercent: function () {
        var allQuests = this.questDataDefault

        var allComplete = allQuests
          .filter(quest => this.$store.get('progress/quest_complete', quest.id) === true)

        return (allComplete.length / allQuests.length) * 100
      },
    },
    mounted () {
    },
    methods: {
    },
    metaInfo: {
      // Children can override the title.
      title: 'Progression',
      // Define meta tags here.
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'View and manage quests by Trader' },
      ],
    },
  }
</script>
<style lang="sass">
.trader-avatar
  border-radius: 50% !important

</style>
