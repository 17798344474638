var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter by item, quest, trader, or hideout module","single-line":"","hide-details":"","tabindex":"1"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clearFilter()}},model:{value:(_vm.filterString),callback:function ($$v) {_vm.filterString=$$v},expression:"filterString"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"ml-1",attrs:{"label":("" + (_vm.hideObtained ? 'Hide Obtained' : 'Show Obtained'))},model:{value:(_vm.hideObtained),callback:function ($$v) {_vm.hideObtained=$$v},expression:"hideObtained"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"ml-1",attrs:{"label":("" + (_vm.neededTotals ? 'Show Totals' : 'Show Details'))},model:{value:(_vm.neededTotals),callback:function ($$v) {_vm.neededTotals=$$v},expression:"neededTotals"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.neededTotals),expression:"neededTotals"}],attrs:{"headers":_vm.totalsHeaders,"items":_vm.totalsResult,"item-class":_vm.rowClasses,"search":_vm.filterString,"hide-default-footer":true,"group-desc":"","multi-sort":"","disable-pagination":""},on:{"update:search":function($event){_vm.filterString=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" "),('fir' in item && item.fir === true)?_c('span',[_c('v-icon',{staticClass:"icon-align",attrs:{"small":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")])],1):_vm._e()])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"background-color":"transparent","active-class":"none","borderless":""}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.editToggleTotalHaveCount(item)}}},[_vm._v(" "+_vm._s(item.have)+" / "+_vm._s(item.number)+" ")])],1)]}},{key:"item.for",fn:function(ref){
var item = ref.item;
return [_vm._l((item.for.quests),function(questNeed,index){return _c('span',[_c('quest-link',{attrs:{"quest-id":questNeed.quest.id}})],1)}),_vm._l((item.for.hideout),function(hideoutModule,index){return _c('span',[_vm._v(" "+_vm._s(hideoutModule.name)+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Level "+_vm._s(hideoutModule.level)+" ")])])})]}}])}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.neededTotals),expression:"!neededTotals"}],attrs:{"headers":_vm.detailsHeaders,"items":_vm.sortUnlocked,"item-class":_vm.rowClasses,"search":_vm.filterString,"hide-default-footer":true,"group-by":"type","group-desc":"","multi-sort":"","disable-pagination":""},on:{"update:search":function($event){_vm.filterString=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" "),('fir' in item && item.fir === true)?_c('span',[_c('v-icon',{staticClass:"icon-align",attrs:{"small":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")])],1):_vm._e(),(item.teamHave && Object.keys(item.teamHave).length > 0)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 icon-align",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-group ")])]}}],null,true)},[_c('span',_vm._l((Object.values(item.teamHave)),function(amountHave,valueIndex){return _c('div',{key:valueIndex},[_c('teammate-identity',{attrs:{"teammate":_vm.$root.team[Object.keys(item.teamHave)[valueIndex]],"left":""}}),_vm._v(" needs "+_vm._s(item.number - amountHave)+" ")],1)}),0)])],1):_vm._e()])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [(item.type === 'quest' ? !_vm.$store.get('progress/objective_complete', item.objective) : !_vm.$store.get('progress/hideout_objective_complete', item.objective))?_c('span',[_c('v-btn-toggle',{attrs:{"background-color":"transparent","active-class":"none","borderless":""}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editDecreaseHaveCount(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.editToggleHaveCount(item)}}},[_vm._v(" "+_vm._s(item.have)+" / "+_vm._s(item.number)+" ")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editIncreaseHaveCount(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1)],1):_vm._e()]}},{key:"item.unlocked",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.type === 'quest')?_c('span',[_c('quest-link',{attrs:{"quest-id":item.quest.id}})],1):(item.type === 'hideout')?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$root.hideoutStationDictionary[item.for.stationId].locales.en)+" Level "+_vm._s(item.forLevel)+" ")]):_vm._e()]),('quest' in item)?_c('div',[(item.unlocked)?_c('span',[_c('v-icon',{staticClass:"icon-align",attrs:{"small":""}},[_vm._v(" mdi-lock-open ")]),_vm._v(" "+_vm._s(item.unlocked)+" before ")],1):_vm._e(),(item.nokappa)?_c('span',[_c('v-chip',{staticClass:"ma-1 font-weight-bold",attrs:{"x-small":"","color":"error"}},[_vm._v(" NOT KAPPA ")])],1):_vm._e(),('alternative' in item.quest)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1 font-weight-bold",attrs:{"x-small":"","color":"info"}},'v-chip',attrs,false),on),[_vm._v(" ALTERNATIVES ")])]}}],null,true)},[_c('span',[_vm._v(" Complete one of: "),_vm._l((_vm.calculateAlternatives(item.quest)),function(quest,index){return _c('div',{key:index},[_c('b',[_vm._v(_vm._s(quest))])])})],2)])],1):_vm._e()]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }