<template>
  <v-col
    class="text-center"
    cols="12"
    style="font-size: .7rem"
  >
    <div>
      Game content and materials are trademarks and copyrights of Grinding Gear Games and its licensors. All rights reserved.
    </div>
    <div>
      &copy; 2021 - {{ new Date().getFullYear() }} Apologist 
    </div>
  </v-col>
</template>
<script>
  export default {
    name: 'SharedFooter',
    computed: {
      tarkovDataLink: function () {
        return `https://github.com/TarkovTracker/tarkovdata/tree/${this.$root.$data.dataHash}`
      },
    },
  }
</script>
