<template>
  <div>
    <span v-show="currentHint != ''">
      Hint: {{ currentHint }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'TrackerHint',
    data () {
      return {
        currentHint: '',
        hintList: [
          "Path of Exile hints need work",
        ],
      }
    },
    mounted () {
      this.randomizeHint()
      this.$router.afterEach((to, from) => {
        this.randomizeHint()
      })
    },
    methods: {
      randomizeHint () {
        this.currentHint = this.hintList[Math.floor(Math.random() * this.hintList.length)]
      },
    },
  }
</script>
