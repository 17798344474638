import Vue from 'vue'
import questData from '../tarkovdata/quests.json'
import hideoutData from '../tarkovdata/hideout.json'
import makeTeamStore from './store/teamstore.js'

// Global TarkovTracker Vue Mixin
export default {
  computed: {
    questArray: function() {
      return this.questDataDefault
        .filter(x => x.deprecated !== true)
    },
    questDictionary: function() {
      return this.questDataDefault.reduce((a, x) => ({ ...a, [x.title]: x }), {})
    },
    questDictionaryId: function() {
      return this.questDataDefault.reduce((a, x) => ({ ...a, [x.id]: x }), {})
    },
    objectiveArray: function() {
      return this.questDataDefault
        .filter(x => x.deprecated !== true)
        .reduce((acc, x) => acc.concat(x.objectives), []) // Get a flat list of objectives
    },
    objectiveDictionary: function() {
      return this.objectiveArray
        .reduce((a, x) => ({ ...a, [x.id]: x }), {}) // Reduce to a mapping of ID to objective
    },
    me: function() {
      return {
        store: this.$store,
        id: this.$store.copy('app/get_user_auth_uid') || 'self',
        dynamic: (this.$store.copy('firesys') != null && this.$store.copy('firesys/team') != null) || false,
        self: true,
        version: {
          major: this.$root.$data.overallVersion,
          data: this.$root.$data.dataHash,
        },
      }
    },
    staticTeammates: function() {
      var teammates = []
      var staticTeammates = this.$store.copy('user/get_static_teammates')

      if (this.$store.copy('user/useTeammates')) {
        staticTeammates.forEach((teammate, i) => {
          var staticTeammate = teammate
          staticTeammate.dynamic = false
          staticTeammate.store = makeTeamStore()
          staticTeammate.store.set('progress/import_teamshare!', staticTeammate.teamshare)
          teammates.push(staticTeammate)
        })
      }

      return teammates
    },
    liveTeammates: function() {
      var teammates = []
      if (this.$store.copy('app/get_user_auth_uid')) {
        var fireSys = this.$store.copy('firesys')
        if (fireSys && fireSys.team && fireSys.team.members) {
          fireSys.team.members.forEach((userId) => {
            if (userId != this.$store.copy('app/get_user_auth_uid')) {
              var dynamicTeammate = {
                dynamic: true,
                id: userId,
                store: makeTeamStore(),
                exportTime: Date.now(),
                version: {
                  major: this.$root.$data.overallVersion,
                  data: this.$root.$data.dataHash,
                },
              }
              dynamicTeammate.store.set('bindProgress!', userId)
              teammates.push(dynamicTeammate)
            }
          })
        }
      }
      return teammates
    },
    teammates: function() {
      return [...this.liveTeammates, ...this.staticTeammates]
    },
    team: function() {
      return [this.me, ...this.teammates]
    },
    teamAvailability: function() {
      var teamAvailability = {}
      // Creats a matrix of quest availability for you and the members of your team
      this.team.forEach((member, teamIndex) => {
        teamAvailability[teamIndex] = {}
        this.questArray.forEach((quest) => {
          teamAvailability[teamIndex][quest.id] = this.isQuestAvailable(quest, member.store)
        }, this)
      }, this)
      return teamAvailability
    },
    questAvailability: function() {
      // Creats a matrix of quest availability for you and the members of your team
      var questAvailability = {}
      this.questArray.forEach((quest) => {
        questAvailability[quest.id] = {}
        this.team.forEach((member, teamIndex) => {
          questAvailability[quest.id][teamIndex] = this.isQuestAvailable(quest, member.store)
        }, this)
      }, this)

      return questAvailability
    },
    objectiveAvailability: function() {
      // Creats a matrix of objective availability for you and the members of your team
      var objectiveAvailability = {}
      this.objectiveArray.forEach((objective) => {
        objectiveAvailability[objective.id] = {}
        this.team.forEach((member, teamIndex) => {
          objectiveAvailability[objective.id][teamIndex] = member.store.copy('progress/objective_complete', objective.id)
        }, this)
      }, this)

      return objectiveAvailability
    },
    questItems: function () {
    	var questItems = []
    	this.questArray.forEach((quest) => {
    		quest.objectives.forEach((objective) => {
    			if (['find', 'collect', 'hideout'].indexOf(objective.type) >= 0 && Object.values(this.objectiveAvailability[objective.id]).some(completed => completed == false)) {
    				var teamHave = {}
    				Object.values(this.objectiveAvailability[objective.id]).forEach((completed, teamIndex) => {
    					if (!completed && teamIndex != 0 && this.team[teamIndex].store.get('progress/objective_have', objective.id) < objective.number) {
    						teamHave[teamIndex] = this.team[teamIndex].store.get('progress/objective_have', objective.id)
    					}
    				}, this)
    				questItems.push(
	                {
	                  name: objective.target,
	                  have: this.$store.get('progress/objective_have', objective.id),
	                  teamHave: teamHave,
	                  number: objective.number,
	                  for: quest.giver,
	                  quest: quest,
	                  objective: objective.id,
	                  fir: (objective.type == 'find'),
	                  unlocked: this.calculateUnlocked(quest, this.$store),
	                  type: 'quest',
	                  nokappa: quest.nokappa,
	                })
    			}
    		}, this)
    	}, this)
    	return questItems
    },
  },
}
