// Imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

import Progression from '@/views/Progression.vue'
import ProgressionTable from '@/views/ProgressionTable.vue'

import QuestInfo from '@/views/QuestInfo.vue'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      route('Dashboard'),

      // TarkovTracker Pages
      // route('Overview', null, 'overview'),
      //route('Needed Items', null, 'gather'),
      route('Progression', null, 'progression'),
      route('Settings', null, 'settings'),
      // { path: '/trader', redirect: '/trader/prapor' },
      { path: '/progession', redirect: '/progression/available' },
      {
        name: 'Progression',
        path: '/progression',
        component: Progression,
        props: true,
        children: [
          {
            path: '/progression/:availability',
            component: ProgressionTable,
          },
        ],
      },
      // { path: '/maps', redirect: '/maps/factory' },
      // {
      //   name: 'Maps',
      //   path: '/maps',
      //   component: Maps,
      //   props: true,
      //   children: [
      //     {
      //       name: 'Map',
      //       path: '/maps/:mapName',
      //       component: MapsQuestTable,
      //     },
      //   ],
      // },
      // { path: '/hideout', redirect: '/hideout/available' },
      // {
      //   path: '/hideout',
      //   component: Hideout,
      //   props: true,
      //   children: [
      //     {
      //       path: '/hideout/:availability',
      //       component: HideoutTable,
      //     },
      //   ],
      // },
      route('Contributors', null, 'contributors'),
      // Pages
      route('Timeline', null, 'components/timeline'),
      route('UserProfile', null, 'components/profile'),

      // Components
      route('Buttons', null, 'components/buttons'),
      route('Grid', null, 'components/grid'),
      route('Tabs', null, 'components/tabs'),
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Forms
      route('Regular Forms', null, 'forms/regular'),
      route('Extended Forms', null, 'forms/extended'),
      route('Validation Forms', null, 'forms/validation'),
      route('Wizard', null, 'forms/wizard'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),
      route('Extended Tables', null, 'tables/extended'),
      route('Data Tables', null, 'tables/data-tables'),

      // Maps
      route('Google Maps', null, 'maps/google'),
      route('Fullscreen Map', null, 'maps/fullscreen'),

      route('Rtl', null, 'pages/rtl'),
      route('Widgets', null, 'widgets'),
      route('Charts', null, 'charts'),
      route('Calendar', null, 'calendar'),
    ]),
    layout('Page', [
      route('Error', null, 'error'),
      // route('Lock', null, 'lock'),
      route('Login', null, 'login'),
      // route('Pricing', null, 'pricing'),
      // route('Register', null, 'register'),
    ]),
  ],
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
