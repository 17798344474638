// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCm5epqRlJ7T7yifjLN5QRGqMHza1ywm28",
  authDomain: "exiletracker.firebaseapp.com",
  projectId: "exiletracker",
  storageBucket: "exiletracker.appspot.com",
  messagingSenderId: "511262966976",
  appId: "1:511262966976:web:03efa3b9019f1e38f85925",
  measurementId: "G-ZTFN7Q4KQY"
};
export default firebaseConfig
